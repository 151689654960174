import React from "react"

import Layout from "@components/layout"
import Page from "@components/page"
import SEO from "@components/seo"

const FeedbackThanksPage = () => (
  <Layout bodyClassName='thanks'>
    <SEO
      title="Thanks!"
      meta={[
        {
          property: `robots`,
          content: `noindex`,
        },
      ]}
    />

    <Page>
      <div className="heroStripe">
        <div className="container">
          <h1 className="pageTitle">Thank you!</h1>
        </div>
      </div>
    </Page>
  </Layout>
);

export default FeedbackThanksPage
